<template lang="html">

  <section class="form-login">
    <Waypoint effect="fadeIn">
      <Form ref="formObject" @submit="onSubmit" class="container-form">
        <div class="column three-width">
          <Field v-model="form.username" name="username" type="text" class="form-control" rules="required"
            :placeholder="$t('fields.user')" />
          <ErrorMessage name="user" />
        </div>
        <div class="column three-width">
          <Field v-model="form.password" name="password" type="password" class="form-control" rules="required"
            :placeholder="$t('fields.password')" />
          <ErrorMessage name="password" />
        </div>
        <button>
          {{ $t('fields.enter') }}
        </button>

      </Form>
    </Waypoint>
  </section>

</template>

<script lang="js">
import { Waypoint } from 'vue-waypoint'
import * as rules from '@vee-validate/rules';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { auth } from '@/store/auth';
export default {
  name: 'form-contact',
  props: [],
  components: {
    Form,
    Field,
    ErrorMessage,
    Waypoint
  },
  setup() {
    const store = auth();
    return {
      store
    }
  },
  mounted() {

  },
  data() {
    return {
      loading: false,
      sended: false,
      form: {
        username: null,
        password: null,
      },
    }
  },
  methods: {
    onSubmit(values, actions) {
      var promise = this.axios.post('auth/login', values);
      promise.then(result => {
        this.store.login = result.data.user;
        this.store.token = result.data.token;
        this.$router.push({ name: 'home' });
      }).catch(error => {
        alert("Usuario o contraseña incorrectos");
      });
    }

  },
}
</script>