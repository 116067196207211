<template lang="html">

  <section class="login">
    <div class="container">
      <img src="/img/logo.svg" alt="Alumni Francisco de Vitoria">
      <formLogin></formLogin>
      <div class="rss-container">
        <a href="https://www.facebook.com/AlumniUFV" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/alumniufv" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </section>

</template>

<script lang="js">
  import formLogin from '@/components/formLogin';
  export default {
    name: 'login',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    components: {
      formLogin
    },
  }
</script>